export const RequestTypes = {
  GALLONS: 'Gallons',
  TONS: 'Tons',
  FULL_LOAD: 'Full Load',
};

export const OrderStatus = {
  NEW: 'New',
  ORDERED: 'Ordered',
  COMPLETED: 'Completed',
};
