import { Progress } from '@components/Progress/Progress';
import { Box, Chip, Stack, Tooltip, Typography, styled } from '@mui/material';
import { useSlice } from '@redux-hooks';
import React, { FC, useEffect } from 'react';
import { Intermediate } from '../../../../types';

interface Props {
  [key: string]: any;
}

const CustomChip = styled(Chip)(() => ({}));
export const ChipViewHasInventoryOf: FC<Props> = (props: Props) => {
  const [intermediatesState] = useSlice('intermediates');
  const [terminalsState] = useSlice('terminals');
  const intermediateData = intermediatesState.intermediatesHasInventoryOf;
  const intermediateIsFetching = intermediatesState.intermediateIsFetching;
  const productGroup = intermediatesState.productGroups;
  const productGroupOptions = intermediatesState.productGroupOptions;
  const terminal = terminalsState.terminal;

  return (
    <>
      <Progress isFetching={intermediateIsFetching} />
      {/* terminalsState.terminal && Array.isArray(intermediateData) && intermediateData.length > 0 ? (
        productGroup.length > 0 ? (
          productGroup.map((pg) => {
            return (
              <Box key={`product-group-${pg}`} sx={{ borderBottom: '1px dashed', mb: 1, pb: 1 }}>
                <Typography variant="subtitle2">{pg}</Typography>
                {intermediateData
                  .filter((p: any) => p.group == pg)
                  .map((product: any) => {
                    return (
                      <Chip
                        key={`product-${product.name}`}
                        label={product.abbreviation}
                        color="secondary"
                        sx={{ margin: '0 10px 10px 0' }}
                      />
                    );
                  })}
              </Box>
            );
          })
        ) : (
          productGroupOptions.map((pg) => {
            return (
              <Box key={`product-group-${pg}`} sx={{ borderBottom: '1px dashed', mb: 1, pb: 1 }}>
                <Typography variant="subtitle2">{pg}</Typography>
                <Stack direction="row" spacing={1}>
                  {intermediateData
                    .filter((p) => p.group == pg)
                    .map((product) => {
                      return <Chip key={`product-${product.name}`} label={product.abbreviation} color="secondary" />;
                    })}
                </Stack>
              </Box>
            );
          })
        )
      ) : (
        <Box>There are no products for this terminal. Click 'Modify' to add products.</Box>
      ) */}
      <Typography variant="subtitle2">{`${terminal?.abbreviation} has inventory of`}</Typography>
      {intermediateData && intermediateData.length > 0 ? (
        <Box key={'has_inventory_of'} sx={{ borderBottom: '1px dashed', mb: 1, pb: 1 }}>
          {intermediateData.map((intermediate: Intermediate, index) => {
            const id = intermediate.number + ' ' + intermediate.name;
            return (
              <Tooltip key={`product-${intermediate.name}`} title={intermediate.startDate.split("T")[0] + " to " + intermediate.endDate.split("T")[0]}>
                <Chip
                  key={`product-${intermediate.name}`}
                  label={intermediate.abbreviation}
                  color={id === 'HAS_INVENTORY_OF' ? 'primary' : 'secondary'}
                  sx={{ margin: '0 10px 10px 0' }}
                />
              </Tooltip>
            );
          })}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};
