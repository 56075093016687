import { CircularProgress } from '@mui/material';
import React, { FC } from 'react';

interface Props {
    isFetching: boolean;
}

export const Progress: FC<Props> = (props: Props) => {
    const { isFetching } = props;
    return (
        <>
      {isFetching ? (
        <CircularProgress
          size={140}
          color='inherit'
          style={{
            position: 'fixed',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
          }}
        />
      ) : null}
      </>
    );
}
