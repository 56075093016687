import { z } from 'zod';

const ShipToSchema = z.object({
  DetailTradeNumber: z.string(),
  ShipToLocationNumber: z.string(),
  ShipToLocationAbbreviation: z.string(),
  EffectiveFromDate: z.string(),
  EffectiveToDate: z.string(),
  ActiveFlag: z.string(),
  ModifyUserCode: z.string(),
  ModifyTimestamp: z.string(),
  CreateUserCode: z.string(),
  CreateTimestamp: z.string(),
});

export type ShipTo = z.infer<typeof ShipToSchema>;

const NotesSchema = z.object({
  DetailTradeNumber: z.string(),
  NoteTypeCode: z.string(),
  ContractOverrideFlag: z.string(),
  NoteText: z.string(),
  CreateUserCode: z.string(),
  CreateTimestamp: z.string(),
  ModifyUserCode: z.string(),
  ModifyTimestamp: z.string(),
});

export type Notes = z.infer<typeof NotesSchema>;

const DetailSchema = z.object({
  DetailTradeNumber: z.string(),
  TradeNumber: z.string(),
  ProductNumber: z.string(),
  ProductAbbreviation: z.string(),
  DetailTradeStatusCode: z.string(),
  MethodOfTransportationCode: z.string(),
  TransportationArrangedByCode: z.string(),
  TransportationPaidByCode: z.string(),
  TransportationPaidHowCode: z.string(),
  TitleTransferLocationNumber: z.string(),
  TitleTransferPointCode: z.string(),
  FinalDestinationLocationNumber: z.string(),
  IntercompanyLocationNumber: z.string(),
  OriginLocationNumber: z.string(),
  OriginLocationAbbreviation: z.string(),
  DeliveryTermsCode: z.string(),
  DeliveryTimeStartDate: z.string(),
  DeliveryTimeEndDate: z.string(),
  Quantity: z.string(),
  QuantityUnitOfMeasureCode: z.string(),
  ExpectedQuantity: z.string(),
  StopIndicator: z.string(),
  ProjectName: z.string(),
  ProjectType: z.string(),
  Freight: z.string(),
  ToleranceTypeCode: z.string(),
  CertificateTypeNumber: z.string(),
  CreateUserCode: z.string(),
  CreateTimestamp: z.string(),
  ModifyUserCode: z.string(),
  ModifyTimestamp: z.string(),
  PriceUnitOfMeasureCode: z.string(),
  PriceCurrencyCode: z.string(),
  PriceTypeCode: z.string(),
  PricePrecision: z.string(),
  TradePrice: z.string(),
  ComplexPrice: z.string(),
  ShipTo: z.array(ShipToSchema),
  Notes: z.array(NotesSchema),
});

export type Detail = z.infer<typeof DetailSchema>;

const HeaderSchema = z.object({
  MasterTradeNumber: z.string(),
  TradeNumber: z.string(),
  TradeDate: z.string(),
  IntracompanyMeleFlag: z.string(),
  TradeTypeCode: z.string(),
  TradeStatusCode: z.string(),
  TradeOwnerFlag: z.string(),
  InternalFlag: z.string(),
  StrategyNumber: z.string(),
  TraderCode: z.string(),
  GoverningLawNumber: z.string(),
  CounterpartyBusinessAssociateNumber: z.string(),
  CounterpartySignatureRequiredFlag: z.string(),
  CommissionAppliesFlag: z.string(),
  TradeModifiedUserCode: z.string(),
  TradeModifiedTimestamp: z.string(),
  CreateUserCode: z.string(),
  CreateTimestamp: z.string(),
  ModifyUserCode: z.string(),
  ModifyTimestamp: z.string(),
  MeleNumber: z.string(),
  CreditTermCode: z.string(),
  PaymentMethodCode: z.string(),
  ProfileFlag: z.string(),
  CounterpartyBusinessAssociateAbbreviation: z.string(),
  LegalEntityNumber: z.string(),
  TraderName: z.string(),
  TransactionOwnerMele: z.string(),
  BuyerTransactionOwnerMele: z.object({
    '@xml:space': z.string(),
    '#significant-whitespace': z.string(),
  }),
  StrategyDescription: z.string(),
  BuySellCode: z.string(),
  ImportExportCode: z.string(),
  ImportOfRecordCode: z.string(),
  ExportOfRecordCode: z.string(),
  HoldInvoiceFlag: z.string(),
  PaymentTermCode: z.string(),
  TradeOwner: z.string(),
  IntentToExport: z.string(),
  Detail: z.array(DetailSchema),
});

export type Header = z.infer<typeof HeaderSchema>;

const PhysicalAgreementEventSchema = z.object({
  Header: HeaderSchema,
});

export type PhysicalAgreementEvent = z.infer<typeof PhysicalAgreementEventSchema>;
