import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { OrderForm, BoolFlag } from '@shared/types';
import { InternalTransferDetails } from './InternalTransferDetails';
import { useParams } from 'react-router-dom';
import { api } from '@api';
import { skipToken } from '@reduxjs/toolkit/query';

const defaultValues: OrderForm = {
  OrderId: 0,
  BaNumber: 2078,
  OrderedByFirstName: '',
  OrderedByLastName: '',
  PhoneNumber: '',
  Email: '',
  Acknowledgement: '',
  SalesAgreementNumber: 0,
  DestinationId: 0,
  Destination: '',
  Terminal: 0,
  DeliveryDate: '',
  Product: 0,
  RequestAmount: null,
  RequestType: 'Full Load',
  Comments: '',
  Carrier: '',
  JobDescription: '',
  AmountLoaded: null,
  Status: '',
  StatusFlag: BoolFlag.False,
  DeliveredLoadFlag: 'N',
  DestNotInList: BoolFlag.False,
};

export const InternalTransferCreateForm = () => {
  const { id } = useParams();
  const { data: transfer, isFetching: isFetchingTransfer } = api.endpoints.getOrder.useQuery(id ? +id : skipToken);

  const methods = useForm<OrderForm>({
    reValidateMode: 'onChange',
    mode: 'all',
    defaultValues,
  });

  useEffect(() => {
    if (transfer) {
      methods.reset({
        ...defaultValues,
        ...transfer,
        DeliveryDate: transfer.DeliveryDate?.split('T')[0] || '',
      });
    }
  }, [transfer]);

  return (
    <FormProvider {...methods}>
      <InternalTransferDetails isFetchingTransfer={isFetchingTransfer} />
    </FormProvider>
  );
};
