import { AnyObject } from '@fhr/react';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TagType } from '../tags';
import { resources } from '@shared/constants';

export const buildOptimizationEndpoints = (builder: EndpointBuilder<any, TagType, any>) => ({
    updateOptimization: builder.mutation<
        AnyObject,
        {
            queryName: 'add' | 'remove';
            recipe: string;
            terminal: string;
            email: string;
        }
    >({
        query: (payload) => ({
            url: `${resources.OPTIMIZATION}`,
            method: 'POST',
            body: payload,
        }),
        invalidatesTags: [],
    }),
});
