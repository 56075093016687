import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { Controller, ControllerProps } from 'react-hook-form';

type ControlledTextFieldProps = Omit<ControllerProps<any>, 'render'> & TextFieldProps;
export const ControlledTextField = (props: ControlledTextFieldProps) => {
  const { control, name, label, required, type, inputProps, rules, helperText, ...rest } = props;
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState }) => {
        return (
          <TextField
            {...field}
            label={label}
            size="small"
            type={type ?? 'text'}
            sx={{ m: 1, minWidth: 200 }}
            helperText={fieldState.error?.message ?? helperText ?? ` `}
            error={!!fieldState.error}
            required={required}
            inputProps={inputProps}
            {...rest}
          />
        );
      }}
    />
  );
};
