//Tags: https://redux-toolkit.js.org/rtk-query/usage/queries - Query Cache Keys
export const tagTypes = [
    'TerminalDetail',
    'Recipes',
    'Recipe-Terminal',
    'Orders',
    'Product',
    'TransactionInfo',
    'TerminalProducts',
    'Users',
] as const;
export type TagType = (typeof tagTypes)[number];
export const makeListTag = <T>(tag: T) => ({ type: tag, id: 'LIST' });
export const addListTags =
    <T, K>(tag: K, idKey: keyof T) =>
    (result: T[] | undefined) =>
        result
            ? [...result.map((item) => ({ type: tag, id: item[idKey] + '' })), makeListTag(tag)]
            : [makeListTag(tag)];
