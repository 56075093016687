import { Route } from '@fhr/react';
import { LocalShipping } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import React from 'react';
import { Suspense } from 'react';

const TerminalPage = React.lazy(() => import('../pages/terminals/TerminalPage'));

export const terminalRoutes = (): Route[] => {
  return [
    {
      path: 'terminals',
      label: 'Terminals',
      icon: <LocalShipping />,
      element: (
        <Suspense fallback={<Skeleton variant="rectangular" height="calc(100vh - 50px)" width="100vw" />}>
          <TerminalPage />
        </Suspense>
      ),
    },
  ];
};
