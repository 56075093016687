import { AnyObject } from '@fhr/react';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TagType } from '../tags';
import { resources } from '@shared/constants';
import { Product } from '@shared/types';

// For more information on how to create an api see: https://redux-toolkit.js.org/rtk-query/overview
export const buildProductsEndpoints = (builder: EndpointBuilder<any, TagType, any>) => ({
    getProducts: builder.query<Product[], void | AnyObject>({
        query: (params) => ({
            url: `${resources.PRODUCTS}`,
            params: params,
        }),
    }),
});
