import { Button, Container } from '@mui/material';
import React, { useState } from 'react';
import { DataGridPro, GridColDef, GridRowModel, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { UserToolbar } from './UserToolbar';
import { Delete, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { api } from '@api';
import { Profile } from '../../auth/profile';
import { formatPhoneNumber } from '@util';
import { useCognitoProfile } from '@hooks';

const headers = {
    'custom:baNumber': 'Business Associate Number',
    'custom:company_name': 'Company Name',
    given_name: 'First Name',
    family_name: 'Last Name',
    Username: 'Username',
    email: 'Email',
    phone_number: 'Phone Number',
};

const columns: GridColDef[] = [];
Object.keys(headers).forEach((key) => {
    if (key == 'phone_number') {
        columns.push({
            field: key,
            headerName: headers[key],
            valueGetter: (params: any) => {
                return formatPhoneNumber(params.value);
            },
            minWidth: 150,
        });
    } else {
        columns.push({
            field: key,
            headerName: headers[key],
            minWidth: 150,
        });
    }
});

columns.push();

export const UserList = () => {
    const navigate = useNavigate();
    const profile = useCognitoProfile();
    const canView = profile?.permissions?.includes('ORDERSADMIN');

    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

    // queries
    const { data: users = [], isFetching: isFetchingUsers } = api.endpoints.getUsers.useQuery();
    const [deleteUser, { isLoading: isDeletingUser }] = api.endpoints.deleteUser.useMutation();

    const handleDeleteClick = async () => {
        for (const row of selectedRows) {
            await deleteUser(row as string);
        }
        setSelectedRows([]);
    };

    const handleEditClick = (row: GridRowModel) => {
        navigate(`/users/${row.id}`, { state: { user: row } });
    };

    const handleDeleteSingleRowClick = async (row: GridRowModel) => {
        await deleteUser(row.id as string);
    };

    return (
        <Container maxWidth="xl" sx={{ maxWidth: 3000 }}>
            {/* Data */}
            <DataGridPro
                rows={users}
                loading={isFetchingUsers}
                columns={[
                    ...columns,
                    ...(canView
                        ? [
                              {
                                  field: 'action',
                                  headerName: 'Action',
                                  sortable: false,
                                  minWidth: 200,
                                  renderCell: (params) => {
                                      return (
                                          <>
                                              <Button
                                                  startIcon={<Edit />}
                                                  onClick={() => handleEditClick(params.row)}
                                              ></Button>
                                              <Button
                                                  color={'error'}
                                                  startIcon={<Delete />}
                                                  onClick={() => handleDeleteSingleRowClick(params.row)}
                                              ></Button>
                                          </>
                                      );
                                  },
                              },
                          ]
                        : []),
                ]}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(newSelection) => {
                    setSelectedRows(newSelection);
                }}
                rowSelectionModel={selectedRows}
                slots={{
                    toolbar: UserToolbar,
                }}
                slotProps={{
                    toolbar: {
                        selectedRows: selectedRows,
                        handleDeleteClick: handleDeleteClick,
                    },
                }}
                disableColumnMenu
                disableDensitySelector
                disableColumnSelector
                sx={{ m: 1, gridArea: 'main', height: 'calc(100vh - 64px)' }}
            />
        </Container>
    );
};

export default UserList;
