import { Create } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';

type CustomGridToolbarCreateButtonProps = {
  onClick: () => void;
  displayText?: string;
  startIcon?: React.ReactNode;
};

export const CustomGridToolbarCreateButton = ({
  onClick,
  displayText,
  startIcon,
}: CustomGridToolbarCreateButtonProps) => {
  const text = displayText || 'Create';
  const icon = startIcon || <Create />;
  return (
    <Button variant="text" size="small" startIcon={icon} onClick={onClick}>
      {text}
    </Button>
  );
};
