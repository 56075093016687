import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TagType } from '../tags';
import { resources } from '@shared/constants';
import { TerminalProduct } from '@shared/types';

export const buildRecipeProductEndpoints = (builder: EndpointBuilder<any, TagType, any>) => ({
    getProductsByTerminal: builder.query<TerminalProduct[], { terminal: string }>({
        query: (params) => ({
            url: `${resources.RECIPEPRODUCT}`,
            params: params,
        }),
    }),
    getAllProducts: builder.query<TerminalProduct[], { terminal: '' }>({
        query: (params) => ({
            url: `${resources.RECIPEPRODUCT}`,
            params: params,
        }),
    }),
    getAllRecipes: builder.query<TerminalProduct[], { recipeType: 'non'; terminal: string; product: string }>({
        query: (params) => ({
            url: `${resources.RECIPEPRODUCT}`,
            params: params,
        }),
    }),
    getRecipeByProduct: builder.query<TerminalProduct[], { product: string }>({
        query: (params) => ({
            url: `${resources.RECIPEPRODUCT}`,
            params: params,
        }),
    }),
});
