import { Route } from '@fhr/react';
import { useCognitoProfile } from '@hooks';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack, { StackProps } from '@mui/material/Stack';
import { Fragment, useEffect, useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../appRoutes';
import { AsphaltPortalAuth } from '@shared/common';

export type NavigationPanelProps = {
  StackProps?: StackProps;
};

export function NavigationPanel(props: NavigationPanelProps) {
  const profile = useCognitoProfile();
  const { StackProps } = props;
  const [filteredRoutes, setFilteredRoutes] = useState<Route[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (profile?.permissions.length) {
      const permissions = profile.permissions;
      setFilteredRoutes(
        appRoutes().filter((route) => {
          if (route.label === 'Orders') {
            return AsphaltPortalAuth.includesOA(permissions) || AsphaltPortalAuth.includesOU(permissions);
          } else if (route.label === 'Products') {
            return (
              AsphaltPortalAuth.includesOA(permissions) ||
              AsphaltPortalAuth.includesOU(permissions) ||
              AsphaltPortalAuth.includesBA(permissions) ||
              AsphaltPortalAuth.includesBU(permissions)
            );
          } else if (route.label === 'Recipes') {
            return (
              AsphaltPortalAuth.includesBA(permissions) ||
              AsphaltPortalAuth.includesBU(permissions) ||
              AsphaltPortalAuth.includesOA(permissions)
            );
          } else if (route.label === 'Terminals') {
            return AsphaltPortalAuth.includesBA(permissions) || AsphaltPortalAuth.includesOA(permissions);
          } else if (route.label === 'Users') {
            return AsphaltPortalAuth.includesOA(permissions);
          }
          return true;
        })
      );
    }
  }, [profile]);

  return (
    <Stack
      {...StackProps}
      p={1}
      height="100%"
      bgcolor={(theme) => theme.palette.background.default}
      alignItems="left"
      overflow="auto"
    >
      <List sx={{ flexGrow: 1 }}>
        {filteredRoutes.map((route, index) => (
          <Fragment key={index}>
            {route.path && typeof route.path === 'string' && route.label ? (
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate(route.path || '')}>
                  {route.icon && (
                    <ListItemIcon sx={{ justifyContent: 'center', paddingRight: 1 }}>{route.icon}</ListItemIcon>
                  )}
                  <ListItemText primary={route.label} />
                </ListItemButton>
              </ListItem>
            ) : null}
          </Fragment>
        ))}
      </List>
    </Stack>
  );
}
