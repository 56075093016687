import { Delete } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';

export const CustomGridToolbarDeleteButton = ({ onClick }) => {
    return (
        <Button size="small" variant="contained" color="secondary" startIcon={<Delete />} onClick={onClick}>
            Delete
        </Button>
    );
};
