import { ThemeOptions } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

export const useIsDesktopView = () => {
    const theme = useTheme();
    const isDesktop = useMediaQuery<ThemeOptions>((theme) => theme.breakpoints?.up?.('md') ?? '');

    return isDesktop;
};
