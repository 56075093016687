import { Button, Box, Typography, Container, TextField, Autocomplete } from '@mui/material';
import React from 'react';
import { SubmitHandler, useFormContext, Controller } from 'react-hook-form';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { OutletContext, UpdateOrder, OrderForm } from '@shared/types';
import { OrderStatus } from '@shared/constants';
import { api } from '@api';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ActionInProgress, ControlledTextField, LoadingContentSkeleton } from '@components';
import { useTerminalPrefetch } from '@hooks';
import toast from 'react-hot-toast';

const ActionButton = ({ getValues, onCancel, saveDisabled }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Box sx={{ display: 'flex', width: '30%', justifyContent: 'center', alignItems: 'center' }}>
        <Button
          key="save"
          disabled={saveDisabled}
          variant="contained"
          type="submit"
          color="secondary"
          sx={{ m: 1, width: '40%' }}
        >
          Save
        </Button>
        <Button
          key="cancel"
          variant="contained"
          type="reset"
          color="primary"
          onClick={onCancel}
          sx={{ m: 1, width: '40%' }}
        >
          Cancel
        </Button>
        {/* vvv For development and troubleshooting form values vvv */}
        {/* <Button
          key="getVals"
          variant="contained"
          color="primary"
          sx={{ m: 1, width: '40%' }}
          onClick={() => console.log(getValues())}
        >
          Vals
        </Button> */}
      </Box>
    </Box>
  );
};

type InternalTransferCreateDetailProps = {
  isFetchingTransfer: boolean;
};

export const InternalTransferDetails = ({ isFetchingTransfer }: InternalTransferCreateDetailProps) => {
  const navigate = useNavigate();
  const { isOrdersAdmin = false } = useOutletContext<OutletContext>();

  const { control, reset, handleSubmit, watch, getValues, setValue } = useFormContext<OrderForm>();

  // watches
  const outboundTerminal = watch('Terminal');

  // get terminals non lazy
  const { data: terminals = [], isFetching: isFetchingTerminals } = useTerminalPrefetch();

  // get terminal products lazy terminal_getProducts
  const { data: terminalProducts = [], isFetching: isFetchingTerminalProducts } =
    api.endpoints.terminal_getProducts.useQuery(
      outboundTerminal ? { terminal: outboundTerminal.toString() } : skipToken
    );

  const [postOrder, { isLoading: isPostingOrder }] = api.endpoints.postOrder.useMutation();
  const [patchOrder, { isLoading: isPatchingOrder }] = api.endpoints.patchOrder.useMutation();

  const outboundTerminalOptions = terminals.map((t) => ({ _id: t.number, terminal: t.name }));
  const inboundTerminalOptions = terminals
    .map((t) => ({ _id: t.number, terminal: t.name }))
    .filter((t) => t._id != outboundTerminal);

  const productOptions = terminalProducts.length
    ? terminalProducts.map((p) => ({ _id: p.number, product: p.name }))
    : [];

  // submit handler
  const handleSave: SubmitHandler<OrderForm> = async (data) => {
    const id = data.OrderId;
    // The follwoing exist on OrderForm but now UpdateOrder
    const { RemainingTons, OrderedBy, Balance, ...rest } = data;
    // insert data
    const formattedData: UpdateOrder = {
      ...rest,
      OrderId: id || undefined,
      CompanyName: 'Internal Transfer - FHR',

      SalesAgreementNumber: +data.SalesAgreementNumber,
      Terminal: +data.Terminal,
      Product: +data.Product,

      DeliveryDate: data.DeliveryDate.split('T')[0],
      RequestAmount: +(data.RequestAmount || 0),
      StatusFlag: data.StatusFlag ? 1 : 0,
      Status: data.Status || OrderStatus.NEW,
      AmountLoaded: +(data.AmountLoaded || 0),
    };

    const res = !id ? await postOrder(formattedData) : await patchOrder(formattedData);
    if ((res as { error: any }).error) {
      toast.error((res as { error: any }).error.data.detail);
    } else {
      reset();
      navigate(`/`);
    }
  };

  return isFetchingTransfer ? (
    <LoadingContentSkeleton />
  ) : (
    <Container maxWidth="lg">
      {/* Saving Order Overlay */}
      <ActionInProgress open={isPostingOrder || isPatchingOrder} />

      {/* Internal Transfer Form */}
      <form onSubmit={handleSubmit(handleSave)}>
        {/* save and cancel buttons */}
        <ActionButton
          getValues={getValues}
          onCancel={() => {
            reset();
            navigate('/');
          }}
          saveDisabled={!isOrdersAdmin}
        />
        <>
          {/* Internal Transfer Information */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Box sx={{ display: 'flex', width: '50%' }}>
              <Typography variant={'h4'} sx={{ ml: 2 }}>
                Transfer Information
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Box sx={{ display: 'flex', ml: 1, flexWrap: 'wrap', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                  {/* Outbound Terminal Input */}
                  {/* Not using ControlledAutoComplete due to problems when editing orders */}
                  <Controller
                    control={control}
                    name="Terminal"
                    render={({ field, fieldState }) => {
                      return (
                        <Autocomplete
                          {...field}
                          id="outbound-terminal-select"
                          disabled={!isOrdersAdmin || isFetchingTerminals}
                          onChange={(e, v) => {
                            if (v) {
                              field.onChange(v._id);
                            } else {
                              field.onChange(null);
                            }
                          }}
                          options={outboundTerminalOptions}
                          getOptionLabel={(option: any) => (option ? option.terminal : '')}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              label="Outbound Terminal"
                              variant="outlined"
                              size="small"
                              helperText=" "
                            />
                          )}
                          value={outboundTerminalOptions.find((t) => t._id == +field.value) || null}
                          sx={{ m: 1, minWidth: 200 }}
                        />
                      );
                    }}
                  />

                  {/* Inbound Terminal Input */}
                  {/* Not using ControlledAutoComplete due to problems when editing orders */}
                  <Controller
                    control={control}
                    name="DestinationId"
                    render={({ field, fieldState }) => {
                      return (
                        <Autocomplete
                          {...field}
                          id="inbound-terminal-select"
                          disabled={!isOrdersAdmin || !outboundTerminal}
                          onChange={(e, v) => {
                            if (v) {
                              field.onChange(v._id);
                            } else {
                              field.onChange(null);
                            }
                          }}
                          options={inboundTerminalOptions}
                          getOptionLabel={(option: any) => (option ? option.terminal : '')}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              label="Inbound Terminal"
                              variant="outlined"
                              size="small"
                              helperText=" "
                            />
                          )}
                          value={inboundTerminalOptions.find((t) => t._id == +field.value) || null}
                          sx={{ m: 1, minWidth: 200 }}
                        />
                      );
                    }}
                  />

                  {/* Product Input */}
                  {/* Not using ControlledAutoComplete due to problems when editing orders */}
                  <Controller
                    control={control}
                    name="Product"
                    render={({ field, fieldState }) => {
                      return (
                        <Autocomplete
                          {...field}
                          id="product-select"
                          disabled={!isOrdersAdmin || !outboundTerminal || isFetchingTerminalProducts}
                          onChange={(e, v) => {
                            if (v) {
                              field.onChange(v._id);
                            } else {
                              field.onChange(null);
                            }
                          }}
                          options={productOptions}
                          getOptionLabel={(option: any) => (option ? option.product : '')}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              label="Product"
                              variant="outlined"
                              size="small"
                              helperText=" "
                            />
                          )}
                          value={productOptions.find((prod) => prod._id == +field.value) || null}
                          sx={{ m: 1, minWidth: 200 }}
                        />
                      );
                    }}
                  />
                </Box>

                {/* Delivery Date */}
                <Controller
                  control={control}
                  name="DeliveryDate"
                  render={({ field: { onChange, ...rest }, fieldState }) => {
                    return (
                      <TextField
                        {...rest}
                        value={rest.value || ''}
                        label="Delivery Date"
                        type="date"
                        disabled={!isOrdersAdmin}
                        sx={{ m: 1, minWidth: 200 }}
                        size="small"
                        required
                        helperText={fieldState.error?.message ?? ' '}
                        error={!!fieldState.error}
                        InputLabelProps={{ shrink: true }}
                        onChange={(date) => {
                          onChange(date);
                        }}
                      />
                    );
                  }}
                />

                {/* Amount Requesting */}
                <ControlledTextField
                  name="RequestAmount"
                  label="Amount Requested"
                  control={control}
                  disabled={!isOrdersAdmin}
                  inputProps={{ min: 0, pattern: '\\d*' }}
                  type="number"
                  helperText={'In Loads'}
                  rules={{
                    validate: {
                      greaterThanZero: (value) => +value > 0 || 'Amount Requested must be greater than 0',
                    },
                  }}
                  sx={{ m: 1, minWidth: 200, maxWidth: 200 }}
                />
              </Box>

              {/* Comments */}
              <ControlledTextField
                name="Comments"
                label="Comments"
                control={control}
                disabled={!isOrdersAdmin}
                multiline
                rows={4}
                fullWidth
                sx={{ m: 1 }}
              />
            </Box>
          </Box>
        </>
      </form>
    </Container>
  );
};
