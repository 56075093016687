import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type OrdersState = {
  dynamicFilterSwitches: { [key: string]: boolean };
  dynamicFilters: { [key: string]: any };
  filterMenuItems: string[];
};

const startingFilters = ['Terminal', 'Product', 'Delivery Date', 'Ordered By'].sort((a, b) => a.localeCompare(b));

const initialState: OrdersState = {
  dynamicFilterSwitches: {
    Terminal: false,
    Product: false,
    'Delivery Date': false,
    'Ordered By': false,
  },
  dynamicFilters: {
    Status: '1',
    Terminal: null,
    Product: null,
    'Delivery Date': '',
    'Ordered By': null,
  },
  filterMenuItems: startingFilters,
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    /** Update the filter criteria for the orders page */
    updateDynamicFilterSwitches: (state, { payload }: PayloadAction<{ [key: string]: boolean }>) => ({
      ...state,
      dynamicFilterSwitches: payload,
    }),
    updateDynamicFilters: (state, { payload }: PayloadAction<{ [key: string]: any }>) => ({
      ...state,
      dynamicFilters: payload,
    }),
    /** Update the menu items filter for mobile view */
    updateFilterMenuItems: (state, { payload }: PayloadAction<string[]>) => ({
      ...state,
      filterMenuItems: payload,
    }),
  },
});
