import { Route } from '@fhr/react';
import { ShoppingCart } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import React from 'react';
import { Suspense } from 'react';

const OrderDetailPage = React.lazy(() => import('../pages/orders/OrderDetailPage'));
const OrderCreatePage = React.lazy(() => import('../pages/orders/OrderCreatePage'));
const InternalTransferCreatePage = React.lazy(() => import('../pages/orders/InternalTransferCreatePage'));

export const orderRoutes = (): Route[] => {
  return [
    {
      path: 'orders',
      label: 'Orders',
      icon: <ShoppingCart />,
      element: (
        <Suspense fallback={<Skeleton variant="rectangular" height="calc(100vh - 50px)" width="100vw" />}>
          <OrderDetailPage />
        </Suspense>
      ),
    },
    {
      path: 'orders/create',
      element: (
        <Suspense fallback={<Skeleton variant="rectangular" height="calc(100vh - 50px)" width="100vw" />}>
          <OrderCreatePage />
        </Suspense>
      ),
    },
    {
      path: 'orders/:id',
      element: (
        <Suspense fallback={<Skeleton variant="rectangular" height="calc(100vh - 50px)" width="100vw" />}>
          <OrderCreatePage />
        </Suspense>
      ),
    },
    {
      path: 'internal-transfers/create',
      element: (
        <Suspense fallback={<Skeleton variant="rectangular" height="calc(100vh - 50px)" width="100vw" />}>
          <InternalTransferCreatePage />
        </Suspense>
      ),
    },
    {
      path: 'internal-transfers/:id',
      element: (
        <Suspense fallback={<Skeleton variant="rectangular" height="calc(100vh - 50px)" width="100vw" />}>
          <InternalTransferCreatePage />
        </Suspense>
      ),
    },
  ];
};
