import { Box, Skeleton } from '@mui/material';
import React from 'react';

export const LoadingContentSkeleton = () => {
  return (
    <Box sx={{ height: '100%', width: '100%', p: 2 }}>
      <Skeleton variant="rounded" animation="wave" height="100%" />
    </Box>
  );
};
