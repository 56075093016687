import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { resources } from '@shared/constants';
import { Intermediate, IntermediateGetOptions, IntermediatePostOptions } from '@shared/types';
import { TagType } from '../tags';

// For more information on how to create an api see: https://redux-toolkit.js.org/rtk-query/overview
export const buildIntermediateEndpoints = (builder: EndpointBuilder<any, TagType, any>) => ({
  getIntermediatesWithTerminals: builder.query<any, Partial<IntermediateGetOptions>>({
    query: (params) => ({
      url: `${resources.INTERMEDIATE}`,
      params: params,
    }),
    providesTags: [],
  }),
  getIntermediatesProducedAt: builder.query<any, IntermediateGetOptions>({
    query: (params) => ({
      url: `${resources.INTERMEDIATE}`,
      params: params,
    }),
    providesTags: [],
  }),
  getIntermediatesInventoryOf: builder.query<any, IntermediateGetOptions>({
    query: (params) => ({
      url: `${resources.INTERMEDIATE}`,
      params: params,
    }),
    providesTags: [],
  }),
  getIntermediatesProjectedUse: builder.query<any, IntermediateGetOptions>({
    query: (params) => ({
      url: `${resources.INTERMEDIATE}`,
      params: params,
    }),
    providesTags: [],
  }),
  saveIntermediatesProducedAt: builder.query<any, IntermediatePostOptions>({
    query: (params) => ({
      url: `${resources.INTERMEDIATE}`,
      method: 'POST',
      params: params,
    }),
    providesTags: [],
  }),
  saveIntermediatesInventoryOf: builder.query<Intermediate, IntermediatePostOptions>({
    query: (params) => ({
      url: `${resources.INTERMEDIATE}`,
      method: 'POST',
      params: params,
    }),
    providesTags: [],
  }),
  saveIntermediatesProjectedUse: builder.query<Intermediate, IntermediatePostOptions>({
    query: (params) => ({
      url: `${resources.INTERMEDIATE}`,
      method: 'POST',
      params: params,
    }),
    providesTags: [],
  }),
});
