import {
  Select,
  Button,
  Box,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Switch,
  FormControlLabel,
  FormHelperText,
  Container,
  TextField,
  Checkbox,
  Autocomplete,
} from '@mui/material';
import React, { useEffect } from 'react';
import { SubmitHandler, useFormContext, Controller } from 'react-hook-form';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { OrderForm, OutletContext, TransactionInfo, UpdateOrder, BoolFlag } from '@shared/types';
import { OrderStatus, RequestTypes } from '@shared/constants';
import { api } from '@api';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  ActionInProgress,
  ControlledAutoComplete,
  ControlledPhoneInput,
  ControlledTextField,
  LoadingContentSkeleton,
} from '@components';
import { createCognitoPhoneString, validateEmailInput, yesNoChoices, requestTypeChoices } from '@util';
import { useCognitoProfile, useCustomerPrefetch, useTransactionInfoPrefetch } from '@hooks';
import { DateTime } from 'luxon';
import toast from 'react-hot-toast';

const TONS_THRESHOLD = 24;
// Jill Richter provided this figure. This is an approximation of the number of gallons in a ton. The real number can vary across products but this will work for our purposes.
const GALS_PER_TON = 235;
const TONS_PER_FULL_LOAD = 25;
const ActionButton = ({ getValues, onCancel, saveDisabled }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Box sx={{ display: 'flex', width: '30%', justifyContent: 'center', alignItems: 'center' }}>
        <Button
          key="save"
          disabled={saveDisabled}
          variant="contained"
          type="submit"
          color="secondary"
          sx={{ m: 1, width: '40%' }}
        >
          Save
        </Button>
        <Button
          key="cancel"
          variant="contained"
          type="reset"
          color="primary"
          onClick={onCancel}
          sx={{ m: 1, width: '40%' }}
        >
          Cancel
        </Button>
        {/* vvv For development and troubleshooting form values vvv */}
        {/* <Button
          key="getVals"
          variant="contained"
          color="primary"
          sx={{ m: 1, width: '40%' }}
          onClick={() => console.log(getValues())}
        >
          Vals
        </Button> */}
      </Box>
    </Box>
  );
};

type OrderCreateDetailProps = {
  isFetchingOrder: boolean;
};

export const OrderDetails = ({ isFetchingOrder }: OrderCreateDetailProps) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { editMode = false } = state || {};
  const { isOrdersAdmin = false } = useOutletContext<OutletContext>();

  const { control, reset, handleSubmit, watch, getValues, setValue } = useFormContext<OrderForm>();
  const profile = useCognitoProfile();
  const ba = profile?.baNumber;
  if (profile && !isOrdersAdmin && ba) {
    setValue('BaNumber', ba, { shouldDirty: false });
    setValue('OrderedByFirstName', profile.firstName || '', { shouldDirty: false });
    setValue('OrderedByLastName', profile.lastName || '', { shouldDirty: false });
    setValue('PhoneNumber', profile.phone_number, { shouldDirty: false });
    setValue('Email', profile.email || '', { shouldDirty: false });
  }

  // watches
  const salesAgreement = watch('SalesAgreementNumber');
  const term = watch('Terminal');
  const prod = watch('Product');
  const company = watch('Company');
  const requestType = watch('RequestType');
  const destNotInList = watch('DestNotInList');
  const deliveredLoad = watch('DeliveredLoadFlag');
  const acknowledgement = watch('Acknowledgement');

  // queries
  const { data: customers = [], isFetching: isFetchingCustomers } = useCustomerPrefetch();
  const { data: transactionInfo = [], isFetching: isFetchingTransactionInfo } = useTransactionInfoPrefetch(
    company?.CNT_PRTY_BA_NO,
    isOrdersAdmin
  );

  const [getDestinations, { data: destinations = [], isFetching: isFetchingDestinations }] =
    api.endpoints.getDestinations.useLazyQuery();

  useEffect(() => {
    if (salesAgreement) {
      getDestinations({ TradeNumber: salesAgreement });
    }
  }, [salesAgreement, destNotInList]);

  const [postOrder, { isLoading: isPostingOrder }] = api.endpoints.postOrder.useMutation();
  const [patchOrder, { isLoading: isPatchingOrder }] = api.endpoints.patchOrder.useMutation();
  const { data: terminalDetail = [], isFetching: isFetchingTerminalDetail } =
    api.endpoints.getTerminalDetailByTerminalNumber.useQuery(term && !isOrdersAdmin ? term.toString() : skipToken);

  const salesAgrOptions =
    company || ba
      ? transactionInfo.filter(
          (info) =>
            info.BA_SYS_NO == (company?.CNT_PRTY_BA_NO || ba) &&
            (!term || info.LC_SYS_NO == term) &&
            (!prod || info.PD_SYS_NO == prod) &&
            (info.REMAINING_QTY_TON > 0 || editMode)
        )
      : [];

  const terminalOptions = salesAgrOptions.length
    ? salesAgrOptions.reduce((acc: { _id: number; terminal: string }[], agr) => {
        const existing = acc.find((t) => t._id === agr.LC_SYS_NO);
        if (!existing && (!salesAgreement || agr.DTL_TRAD_NO == salesAgreement)) {
          acc.push({ _id: agr.LC_SYS_NO, terminal: agr.LC_LC_SH_NM });
        }
        return acc;
      }, [])
    : [];

  const productOptions = salesAgrOptions.length
    ? salesAgrOptions.reduce((acc: { _id: number; product: string }[], agr) => {
        const existing = acc.find((t) => t._id === agr.PD_SYS_NO);
        if (!existing && (!salesAgreement || agr.DTL_TRAD_NO == salesAgreement)) {
          acc.push({ _id: agr.PD_SYS_NO, product: agr.PD_PD_SH_NM });
        }
        return acc;
      }, [])
    : [];

  const agr = salesAgrOptions.find((agr) => agr.DTL_TRAD_NO == salesAgreement);
  let displayTonsRemaining: number = 0;
  let thresholdTonsRemaining: number = 0;
  let displayGalsRemaining: number = 0;
  let thresholdGalsRemaining: number = 0;
  let displayFullLoadsRemaining: number = 0;
  let thresholdFullLoadsRemaining: number = 0;
  let displayRemainingQty = false;
  if (agr) {
    displayTonsRemaining = agr.REMAINING_QTY_TON;
    // Threshold for available for order tons would be 24 over the remaining tons.
    thresholdTonsRemaining = agr.REMAINING_QTY_TON + TONS_THRESHOLD;

    displayGalsRemaining = agr.REMAINING_QTY_GAL;
    // Threshold for available for order gallons would be 24 TONS over the remaining gallons.
    thresholdGalsRemaining = agr.REMAINING_QTY_GAL + TONS_THRESHOLD * GALS_PER_TON;

    // Full Load = 25 TONS
    displayFullLoadsRemaining = agr.REMAINING_QTY_TON / TONS_PER_FULL_LOAD;
    // Threshold for available for order full loads would be 24 TONS over the remaining full loads.
    thresholdFullLoadsRemaining = Math.floor(displayFullLoadsRemaining + TONS_THRESHOLD / TONS_PER_FULL_LOAD);
    displayRemainingQty = true;
  }

  // submit handler
  const handleSave: SubmitHandler<OrderForm> = async (data) => {
    const id = data.OrderId;
    // insert data
    const phone = createCognitoPhoneString(data.PhoneNumber);
    const destination = destinations.find((dest) => dest.DestinationId?.toString() == data.Destination);

    const formattedData: UpdateOrder = {
      OrderId: id || undefined,
      CompanyName: company ? company.BA_BA_SHORT_NM : ba ? profile?.company_name : '',
      BaNumber: company ? +company.CNT_PRTY_BA_NO : ba ? +ba : 0,
      SalesAgreementNumber: +data.SalesAgreementNumber,
      Terminal: +data.Terminal,
      Product: +data.Product,
      RequestType: data.RequestType as any,
      DeliveryDate: data.DeliveryDate.split('T')[0],
      Destination: destination?.DestinationName ?? (destNotInList === BoolFlag.True ? data.Destination : ''),
      DestinationId: destination?.DestinationId ?? 0,
      RequestAmount: +(data.RequestAmount || 0),
      DeliveredLoadFlag: data.DeliveredLoadFlag,
      DestNotInList: data.DestNotInList,
      StatusFlag: data.StatusFlag ? 1 : 0,
      Status: data.Status || OrderStatus.NEW,
      JobDescription: data.JobDescription ?? '',
      Acknowledgement: acknowledgement ? term.toString() : '',
      PhoneNumber: phone,
      Email: data.Email || '',
      OrderedByFirstName: data.OrderedByFirstName || '',
      OrderedByLastName: data.OrderedByLastName || '',
      Carrier: data.Carrier || '',
      AmountLoaded: +(data.AmountLoaded || 0),
      Comments: data.Comments || '',
    };

    const res = !id ? await postOrder(formattedData) : await patchOrder(formattedData);
    if ((res as { error: any }).error) {
      toast.error((res as { error: any }).error.data.detail);
    } else {
      reset();
      navigate(`/`);
    }
  };

  // Validates that the amount the customer is requesting is less than the amount remaining.
  const amountRequestingValidation = (value) => {
    if (isOrdersAdmin) return true;
    if (requestType === 'Gallons') {
      return (
        value <= thresholdGalsRemaining ||
        `Amount Requested is greater than Gallons Remaining: ${thresholdGalsRemaining}`
      );
    } else if (requestType === 'Full Load') {
      return (
        value <= thresholdFullLoadsRemaining ||
        `Amount Requested is greater than Full Loads Remaining: ${thresholdFullLoadsRemaining}`
      );
    } else if (requestType === 'Tons') {
      return (
        value <= thresholdTonsRemaining || `Amount Requested is greater than Tons Remaining: ${thresholdTonsRemaining}`
      );
    } else {
      return true;
    }
  };

  return isFetchingOrder ? (
    <LoadingContentSkeleton />
  ) : (
    <Container maxWidth="lg">
      {/* Saving Order Overlay */}
      <ActionInProgress open={isPostingOrder || isPatchingOrder} />

      {/* Order Form */}
      <form onSubmit={handleSubmit(handleSave)}>
        {/* save and cancel buttons */}
        <ActionButton
          getValues={getValues}
          onCancel={() => {
            reset();
            navigate('/');
          }}
          saveDisabled={!isOrdersAdmin && !acknowledgement}
        />
        {isOrdersAdmin && (
          /* Customer Information */
          <>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Box sx={{ display: 'flex', width: '50%' }}>
                <Typography variant={'h4'} sx={{ ml: 2 }}>
                  Customer Information
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Box sx={{ display: 'flex', ml: 1, flexWrap: 'wrap' }}>
                {/* Company Input */}
                <ControlledAutoComplete
                  name="Company"
                  label="Customer"
                  options={customers}
                  getOptionLabel={(option: any) =>
                    option ? `${option.BA_BA_SHORT_NM} - ${option.CNT_PRTY_BA_NO}` : ''
                  }
                  loading={isFetchingCustomers}
                  sx={{ m: 1, minWidth: 275 }}
                  isOptionEqualToValue={(option, value) => {
                    return option.CNT_PRTY_BA_NO === value.CNT_PRTY_BA_NO;
                  }}
                  control={control}
                  required
                  onChange={(e, v) => {
                    // if customer is reset you need to reset the rest of thr form.
                    reset({ Company: v });
                  }}
                />

                {/* Ordered By First Name Input */}
                <ControlledTextField name="OrderedByFirstName" label="Ordered By First Name" control={control} />

                {/* Ordered By Last Name Input */}
                <ControlledTextField name="OrderedByLastName" label="Ordered By Last Name" control={control} />

                {/* Phone Number Input */}
                <ControlledPhoneInput name="PhoneNumber" label="Phone Number" control={control} />

                {/* Email Input */}
                <ControlledTextField
                  name="Email"
                  label="Email"
                  control={control}
                  rules={{
                    validate: {
                      validEmail: (value) => {
                        if (!value) return true;
                        return validateEmailInput(value as string) || 'Invalid email address';
                      },
                    },
                  }}
                />
              </Box>
            </Box>
          </>
        )}
        <>
          {/* Order Information */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Box sx={{ display: 'flex', width: '50%' }}>
              <Typography variant={'h4'} sx={{ ml: 2 }}>
                Order Information
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Box sx={{ display: 'flex', ml: 1, flexWrap: 'wrap', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                  {/* Sales Agreement Input */}
                  <ControlledAutoComplete
                    name="SalesAgreementNumber"
                    label="Sales Agreement"
                    options={salesAgrOptions}
                    getOptionLabel={(option: any) =>
                      option ? `${option.DTL_TRAD_NO} - ${option.PD_PD_SH_NM} - ${option.LC_LC_SH_NM}` : ''
                    }
                    loading={isFetchingTransactionInfo}
                    disabled={isOrdersAdmin ? !company || (editMode && isFetchingTransactionInfo) : false}
                    sx={{ m: 1, minWidth: 275 }}
                    control={control}
                    valueProperty={'DTL_TRAD_NO'}
                    helperText={
                      displayRemainingQty
                        ? requestType === RequestTypes.GALLONS
                          ? `Gallons Remaining: ${displayGalsRemaining}`
                          : requestType === RequestTypes.FULL_LOAD
                            ? `Full Loads Remaining: ${displayFullLoadsRemaining.toFixed(2)}`
                            : `Tons Remaining: ${displayTonsRemaining}`
                        : ' '
                    }
                    required
                    isOptionEqualToValue={(option, value) => {
                      return option.DTL_TRAD_NO === value.DTL_TRAD_NO;
                    }}
                    onChange={(e, v) => {
                      if (v) {
                        setValue(
                          'Terminal',
                          salesAgrOptions.find((agr: TransactionInfo) => agr.DTL_TRAD_NO == v.DTL_TRAD_NO)?.LC_SYS_NO ||
                            0
                        );

                        setValue(
                          'Product',
                          salesAgrOptions.find((agr: TransactionInfo) => agr.DTL_TRAD_NO == v.DTL_TRAD_NO)?.PD_SYS_NO ||
                            0
                        );
                      } else {
                        setValue('Terminal', 0);
                        setValue('Product', 0);
                      }
                    }}
                  />

                  {/* Terminal Input */}
                  {/* Not using ControlledAutoComplete due to problems when editing orders */}
                  <Controller
                    control={control}
                    name="Terminal"
                    render={({ field, fieldState }) => {
                      return (
                        <Autocomplete
                          {...field}
                          id="terminal-select"
                          disabled={isOrdersAdmin ? !company || (editMode && isFetchingTransactionInfo) : false}
                          onChange={(e, v) => {
                            if (v) {
                              field.onChange(v._id);
                            } else {
                              field.onChange(null);
                            }
                          }}
                          options={terminalOptions}
                          getOptionLabel={(option: any) => (option ? option.terminal : '')}
                          renderInput={(params) => (
                            <TextField {...params} required label="Terminal" variant="outlined" size="small" />
                          )}
                          value={terminalOptions.find((t) => t._id == +field.value) || null}
                          sx={{ m: 1, minWidth: 200 }}
                        />
                      );
                    }}
                  />

                  {/* Product Input */}
                  {/* Not using ControlledAutoComplete due to problems when editing orders */}
                  <Controller
                    control={control}
                    name="Product"
                    render={({ field, fieldState }) => {
                      return (
                        <Autocomplete
                          {...field}
                          id="product-select"
                          disabled={isOrdersAdmin ? !company || (editMode && isFetchingTransactionInfo) : false}
                          onChange={(e, v) => {
                            if (v) {
                              field.onChange(v._id);
                            } else {
                              field.onChange(null);
                            }
                          }}
                          options={productOptions}
                          getOptionLabel={(option: any) => (option ? option.product : '')}
                          renderInput={(params) => (
                            <TextField {...params} required label="Product" variant="outlined" size="small" />
                          )}
                          value={productOptions.find((prod) => prod._id == +field.value) || null}
                          sx={{ m: 1, minWidth: 200 }}
                        />
                      );
                    }}
                  />
                </Box>
                {!isOrdersAdmin && ba && term != 0 && (
                  /* Acknowledgement Input */
                  <Box sx={{ display: 'flex', width: '100%', ml: 1 }}>
                    <Controller
                      control={control}
                      name="Acknowledgement"
                      disabled={!salesAgreement || !term || isFetchingTerminalDetail}
                      render={({ field, fieldState }) => {
                        return (
                          <FormControlLabel
                            {...field}
                            control={<Checkbox />}
                            label={terminalDetail && terminalDetail.length ? terminalDetail[0].terminalmsg : ''}
                            sx={{ m: 1 }}
                          />
                        );
                      }}
                    />
                  </Box>
                )}

                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                  {/* Request Type Input */}
                  <ControlledAutoComplete
                    name="RequestType"
                    label="Request Type"
                    options={requestTypeChoices}
                    getOptionLabel={(option: any) => (option ? option.name : '')}
                    disabled={!salesAgreement || (!isOrdersAdmin && !acknowledgement)}
                    sx={{ m: 1, minWidth: 200 }}
                    control={control}
                    required
                    valueProperty="id"
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                  />

                  {/* Carrier Input */}
                  <ControlledTextField
                    name="Carrier"
                    label="Carrier"
                    disabled={!salesAgreement || (!isOrdersAdmin && !acknowledgement)}
                    control={control}
                    required
                  />

                  {/* Delivery Date */}
                  <Controller
                    control={control}
                    name="DeliveryDate"
                    rules={{
                      validate: {
                        outsideContractEndDateValidation: (value) => {
                          if (!agr || !value || isOrdersAdmin) return true;
                          const deliveryDate = DateTime.fromISO(value).setZone('America/Chicago');
                          const endDate = DateTime.fromISO(agr?.DLV_TI_END_DT).setZone('America/Chicago');
                          const startDate = DateTime.fromISO(agr?.DLV_TI_STRT_DT).setZone('America/Chicago');
                          return (
                            (deliveryDate >= startDate && deliveryDate <= endDate) ||
                            `Agreement delivery date must be between ${startDate.plus({ days: 1 }).toFormat('MM/dd/yyyy')} and ${endDate.plus({ days: 1 }).toFormat('MM/dd/yyyy')}.`
                          );
                        },
                      },
                    }}
                    render={({ field: { onChange, ...rest }, fieldState }) => {
                      return (
                        <TextField
                          {...rest}
                          value={rest.value || ''}
                          label="Delivery Date"
                          type="date"
                          disabled={!salesAgreement || (!isOrdersAdmin && !acknowledgement)}
                          sx={{ m: 1, minWidth: 200 }}
                          size="small"
                          required
                          helperText={fieldState.error?.message ?? ' '}
                          error={!!fieldState.error}
                          InputLabelProps={{ shrink: true }}
                          onChange={(date) => {
                            onChange(date);
                          }}
                        />
                      );
                    }}
                  />

                  {/* Destination */}
                  {/* Not using ControlledAutoComplete due to problems when editing orders */}
                  <Controller
                    control={control}
                    name="Destination"
                    render={({ field, fieldState }) => {
                      return destNotInList === BoolFlag.True ? (
                        <TextField
                          {...field}
                          id="destination-select"
                          disabled={!salesAgreement || (!isOrdersAdmin && !acknowledgement)}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          value={field.value}
                          sx={{ m: 1, minWidth: 200 }}
                          required
                          label="Destination"
                          variant="outlined"
                          size="small"
                          helperText={'Please type your destination.'}
                        />
                      ) : (
                        <Autocomplete
                          {...field}
                          id="destination-select"
                          disabled={!salesAgreement || (!isOrdersAdmin && !acknowledgement)}
                          onChange={(e, v) => {
                            if (v) {
                              field.onChange(v.DestinationId);
                            } else {
                              field.onChange(null);
                            }
                          }}
                          options={destinations}
                          getOptionLabel={(option: any) => {
                            return option ? option.DestinationName : '';
                          }}
                          renderInput={(params) => (
                            <TextField {...params} required label="Destination" variant="outlined" size="small" />
                          )}
                          value={
                            destinations.find((dest) => {
                              return dest.DestinationId == +field.value;
                            }) || null
                          }
                          sx={{ m: 1, minWidth: 200 }}
                          loading={isFetchingDestinations}
                          isOptionEqualToValue={(option, value) => {
                            return option.DestinationId === value.DestinationId;
                          }}
                        />
                      );
                    }}
                  />

                  {/* Destination Not In List Toggle */}
                  <Controller
                    control={control}
                    name="DestNotInList"
                    disabled={!salesAgreement || (!isOrdersAdmin && !acknowledgement)}
                    render={({ field, fieldState }) => {
                      return (
                        <FormControlLabel
                          checked={field.value === BoolFlag.True}
                          onClick={(e) => {
                            setValue('Destination', '');
                          }}
                          sx={{ mb: 2 }}
                          control={
                            <Switch
                              {...field}
                              color="primary"
                              sx={{ mb: 1 }}
                              onChange={(event, newValue) => field.onChange(newValue ? BoolFlag.True : BoolFlag.False)}
                            />
                          }
                          label="Destination Not In List?"
                        />
                      );
                    }}
                  />
                </Box>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                  {/* Amount Requesting */}
                  <ControlledTextField
                    name="RequestAmount"
                    label="Amount Requested"
                    control={control}
                    disabled={!salesAgreement || (!isOrdersAdmin && !acknowledgement)}
                    inputProps={{ min: 0, pattern: '\\d*' }}
                    type="number"
                    rules={{
                      validate: {
                        greaterThanZero: (value) => +value > 0 || 'Amount Requested must be greater than 0',
                        amountRequestingValidation: (value) => amountRequestingValidation(value),
                      },
                    }}
                    sx={{ m: 1, minWidth: 200, maxWidth: 200 }}
                  />

                  {/* Amount Loaded */}
                  <ControlledTextField
                    name="AmountLoaded"
                    label="Amount Loaded"
                    control={control}
                    disabled={!salesAgreement || !isOrdersAdmin}
                    inputProps={{ min: 0, pattern: '\\d*' }}
                    type="number"
                  />
                </Box>

                {/* Comments */}
                <ControlledTextField
                  name="Comments"
                  label="Comments"
                  control={control}
                  disabled={!salesAgreement || (!isOrdersAdmin && !acknowledgement)}
                  multiline
                  rows={4}
                  fullWidth
                  sx={{ m: 1 }}
                />
              </Box>
            </Box>
          </Box>
          {/* Load Information */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Box sx={{ display: 'flex', width: '50%' }}>
              <Typography variant={'h4'} sx={{ ml: 2 }}>
                Load Information
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Box sx={{ display: 'flex', ml: 1, flexWrap: 'wrap', flexDirection: 'column', width: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                {/* Delivered Load */}
                <Controller
                  control={control}
                  name="DeliveredLoadFlag"
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl
                        size="small"
                        disabled={!salesAgreement || (!isOrdersAdmin && !acknowledgement)}
                        required
                        sx={{ m: 1, minWidth: 200 }}
                      >
                        <InputLabel id="del-load-input-label">Is this a delivered load?</InputLabel>
                        <Select
                          {...field}
                          value={field.value || 'N'}
                          labelId="del-load-input-label"
                          label="Is this a delivered load? *"
                          disabled={!salesAgreement || (!isOrdersAdmin && !acknowledgement)}
                          defaultValue={'N'}
                        >
                          {yesNoChoices.map((choice) => (
                            <MenuItem key={choice.id} value={choice.id}>
                              {choice.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>Delivery Arranged by FHR</FormHelperText>
                      </FormControl>
                    );
                  }}
                />
                {/* Job Description */}
                {deliveredLoad === 'Y' && (
                  <ControlledTextField
                    name="JobDescription"
                    label="Job Description"
                    control={control}
                    disabled={!salesAgreement || (!isOrdersAdmin && !acknowledgement)}
                    multiline
                    rows={4}
                    fullWidth
                    sx={{ m: 1 }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </>
      </form>
    </Container>
  );
};
