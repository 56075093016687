import { Route } from '@fhr/react';
import { Group } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import React from 'react';
import { Suspense } from 'react';

const UserPage = React.lazy(() => import('../pages/users/UserPage'));
const UserDetailPage = React.lazy(() => import('../pages/users/UserDetailPage'));

export const userRoutes = (): Route[] => {
  return [
    {
      path: 'users',
      label: 'Users',
      icon: <Group />,
      element: (
        <Suspense fallback={<Skeleton variant="rectangular" height="calc(100vh - 50px)" width="100vw" />}>
          <UserPage />
        </Suspense>
      ),
    },
    {
      path: 'users/:id',
      element: (
        <Suspense fallback={<Skeleton variant="rectangular" height="calc(100vh - 50px)" width="100vw" />}>
          <UserDetailPage />
        </Suspense>
      ),
    },
    {
      path: 'users/create',
      element: (
        <Suspense fallback={<Skeleton variant="rectangular" height="calc(100vh - 50px)" width="100vw" />}>
          <UserDetailPage />
        </Suspense>
      ),
    },
  ];
};
