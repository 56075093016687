import { AnyObject } from '@fhr/react';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TagType } from '../tags';
import { TerminalDetail } from '@shared/types';
import { resources } from '@shared/constants';

// For more information on how to create an api see: https://redux-toolkit.js.org/rtk-query/overview
export const buildTerminalDetailEndpoints = (builder: EndpointBuilder<any, TagType, any>) => ({
    getTerminalDetails: builder.query<TerminalDetail[], void | AnyObject>({
        query: (params) => ({
            url: `${resources.TERMINALDETAIL}`,
            params: params,
        }),
        providesTags: ['TerminalDetail'],
    }),

    postTerminalDetail: builder.mutation<TerminalDetail, Omit<TerminalDetail, 'terminalId'>>({
        query: (body) => ({
            url: `${resources.TERMINALDETAIL}`,
            method: 'POST',
            body: body,
        }),
        invalidatesTags: ['TerminalDetail'],
    }),

    patchTerminalDetail: builder.mutation<
        TerminalDetail,
        Pick<TerminalDetail, 'terminalId'> & Partial<Omit<TerminalDetail, 'terminalId'>>
    >({
        query: (body) => ({
            url: `${resources.TERMINALDETAIL}/${body.terminalId}`,
            method: 'PATCH',
            body: body,
        }),
        invalidatesTags: ['TerminalDetail'],
    }),

    getTerminalDetailByTerminalNumber: builder.query<TerminalDetail[], string>({
        query: (terminalNumber) => `${resources.TERMINALDETAIL}?TerminalNumber=${terminalNumber}`,
        providesTags: ['TerminalDetail'],
    }),
});
