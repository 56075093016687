export const validatePhoneNumberInput = (phoneNumber: string): boolean => {
    const phoneNumberRegex = /^\+?\d{1,3}[- ]?\d{3,4}[- ]?\d{3,4}$/;
    return phoneNumberRegex.test(phoneNumber);
};

export const formatPhoneNumber = (input: string | undefined) => {
    if (!input) return '';
    let phone = input.replace('+1', '');
    const areaCode = phone.slice(0, 3);
    const firstThree = phone.slice(3, 6);
    const lastFour = phone.slice(6, 10);
    phone = `+1 ${areaCode}-${firstThree}-${lastFour}`;
    return phone;
};

export const createCognitoPhoneString = (phoneNumber: string) => {
    if (!phoneNumber) return '';
    if (phoneNumber.startsWith('+1')) {
        phoneNumber = phoneNumber.replace('+1', '');
    }
    return '+1' + phoneNumber.replace(/[^0-9]/g, '');
};
