import { api, redirectOn401 } from '@api';
import { App as FHRApp } from '@fhr/react';
import { slices } from '@redux-hooks';
import { getRoutes } from './routes';
import { Logout, SideBar } from '@components';
import React from 'react';
import { theme } from '@theme';

export const App = () => {
  return (
    <FHRApp
      title="Asphalt Portal"
      slices={slices}
      apis={[api]}
      routes={getRoutes()}
      middleware={[redirectOn401]}
      // fhrId={{ forceFhrIdLogin: true, domain: `https://${appDomain}`}}
      menus={[<Logout />]}
      sideBar={<SideBar />}
      lightTheme={theme}
    />
  );
};
