import { Auth, CognitoUser } from '@aws-amplify/auth';
import { AnyObject } from '@fhr/react';
import { BaseAuthProvider } from './baseProvider';
import { CognitoAuthConfig } from './types';
import { User } from './user';

/**
 * Cognito based Auth Provider for React Admin
 * @augments BaseAuthProvider
 */
export class CognitoAuthProvider extends BaseAuthProvider {
    /**
     * Initializes the auth provider
     * @param config Configuration information to use
     */
    public async initialize(config: CognitoAuthConfig): Promise<string[]> {
        this.logger.debug(`${CognitoAuthProvider.name}.${this.initialize.name}`, config);

        // configure the amplify auth provider
        const baseUrl = `${window.location.protocol}//${window.location.host}`;
        Auth.configure({
            identityPoolId: config.identityPoolId,
            userPoolId: config.userPoolId,
            userPoolWebClientId: config.userPoolWebClientId,
            region: config.region,
            oauth: {
                domain: config.domain,
                scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
                redirectSignIn: `${baseUrl}/`,
                redirectSignOut: `${baseUrl}/`,
                redirectUri: `${baseUrl}/`,
                responseType: config.authFlow === 'implicit' ? 'token' : 'code',
            },
        });

        // if authorization is in progress, finish it
        if (this.getAuthStatus() === 'PENDING') {
            this.setAuthStatus('SUCCESS');
            // this.redirect(this.user ? this.user.state : UnauthorizedRoute);
            return [];
        }

        return this.getPermissions();
    }

    /**
     * Called by React Admin when it wants to start the login process
     * @param params Data about the error
     */
    public login(params?: AnyObject) {
        this.logger.debug(`${CognitoAuthProvider.name}.${this.login.name}`, params);

        // if the login is already in progress, reject the additional login attempt
        if (this.getAuthStatus() === 'PENDING') {
            return Promise.reject(false);
        }

        // start login attempt
        this.setAuthStatus('PENDING');
        return Auth.federatedSignIn();
    }

    /**
     * Called by React Admin when it wants to start the logout process
     */
    public async logout(): Promise<string | void> {
        // this.logger.debug(`${CognitoAuthProvider.name}.${this.logout.name}`);
        try {
            await this.endSso();
            await Auth.signOut({ global: true });
        } catch (error) {
            await Auth.signOut();
        }
        console.log('redirecting to', this.unauthorizedRoute);
        return this.unauthorizedRoute;
    }

    protected async getUser(): Promise<User | undefined> {
        const user: CognitoUser | undefined = await Auth.currentAuthenticatedUser().catch(() => undefined);
        let result: User | undefined;
        if (user) {
            const session = (user as CognitoUser).getSignInUserSession();
            if (session) {
                const at = session.getAccessToken();
                const id = this.filterClaims(session.getIdToken().payload, 'identities', 'token_use');
                Object.keys(id).forEach((k) => {
                    if (k.startsWith('custom:')) {
                        id[k.substr(7)] = id[k];
                        delete id[k];
                    }
                });
                const {
                    email,
                    family_name,
                    given_name,
                    sub,
                    'cognito:username': username,
                    'cognito:groups': roles,
                    personnelNumber,
                    isSupervisor,
                    'pi.sri': piSri,
                    ...rest
                } = id;
                result = {
                    tokenType: 'Bearer',
                    accessToken: at.getJwtToken(),
                    expiresAt: at.getExpiration(),
                    profile: {
                        source: 'cognito',
                        sub: sub,
                        username: username,
                        firstName: given_name,
                        lastName: family_name,
                        email: email,
                        permissions: roles?.map((g: string) => g.toUpperCase()),
                        personnelNumber,
                        isSupervisor: isSupervisor === 'Yes',
                        id: username,
                        fullName: `${given_name} ${family_name}`.trim(),
                        piSri,
                        ...rest,
                    },
                };
            }
        }
        this.logger.debug(`${CognitoAuthProvider.name}.${this.getUser.name}`, result);
        return result;
    }
}
