import { Route } from '@fhr/react';
import { DonutSmall } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import React from 'react';
import { Suspense } from 'react';

const RecipePage = React.lazy(() => import('../pages/recipes/RecipePage'));

export const recipeRoutes = (): Route[] => {
  return [
    {
      path: 'recipes',
      label: 'Recipes',
      icon: <DonutSmall />,
      element: (
        <Suspense fallback={<Skeleton variant="rectangular" height="calc(100vh - 50px)" width="100vw" />}>
          <RecipePage />
        </Suspense>
      ),
    },
  ];
};
